import React from 'react';
import LightweightTable from 'components/LightweightTable';
import AddButton from 'components/AddButton';
import { currencyColumn, textColumn, imageColumn } from 'components/LightweightTable/columns';
import { makeStyles, Typography } from '@material-ui/core';
import { useSponsoredCreation } from '../hooks/useSponsoredCreation';

const useStyles = makeStyles(() => ({
    root: {
        padding: '2rem',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem 0 3rem 0',
    },
    headline: {
        margin: '0',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
    },
    imageCell: {
        minWidth: '75px',
        minHeight: '75px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& > img': {
            maxWidth: '75px',
            maxHeight: '75px',
        },
    },
    decriptionCell: {
        maxWidth: '250px',
    },
}));

const CreateProducts = () => {
    const classes = useStyles();
    const { products } = useSponsoredCreation();

    const columns = [
        imageColumn('image', 'Image', { cellClassName: classes.imageCell }),
        textColumn('description', 'Description', { lineBreak: true, cellClassName: classes.decriptionCell }),
        textColumn('sku', 'SKU'),
        textColumn('color', 'Color'),
        textColumn('upc', 'UPC'),
        textColumn('model', 'Model'),
        currencyColumn('price', 'Price'),
        textColumn('status', 'Status'),
        currencyColumn('bid', 'Bid'),
    ];

    const actions = (item) => [
        {
            label: 'Update Bid',
            onClick: () => {
                // eslint-disable-next-line no-console
                console.log('Update Bid', item);
            },
        },
        {
            label: 'Remove SKU',
            onClick: () => {
                // eslint-disable-next-line no-console
                console.log('Remove SKU', item);
            },
        },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography component="h2" align="left" className={classes.headline}>
                    Products ({products.length})
                </Typography>
                <AddButton label="Products" />
            </div>
            <LightweightTable data={products} columns={columns} actions={actions} />
        </div>
    );
};

export default CreateProducts;
