import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { addAccountAction } from 'containers/OrganizationPage/slice';
import DialogBox from '../../../../components/DialogBox';
import AddEditAccountForm from '../Shared/AddEditAccountForm';

export function AddAccountFlyout({ createAccount, closeFlyout, organizations, brands, accounts }) {
    const [submitValues, setSubmitValues] = useState(null);

    const onSubmit = (values) => {
        setSubmitValues(values);
    };

    const initialValues = {
        organization: [],
        myadsBrand: [],
        providerName: [],
        providerAccountName: '',
        providerAccountId: '',
    };

    const onSubmitAddAccountCancel = () => {
        setSubmitValues(null);
    };

    const onAddAccountSubmitProceed = () => {
        const newOrgValues = {
            orgId: submitValues.organization[0].value,
            brandId: submitValues.myadsBrand[0].value,
            provider: submitValues.providerName[0].value,
            providerAccountName: submitValues.providerAccountName,
            providerAccountId: submitValues.providerAccountId,
        };
        createAccount(newOrgValues);
        closeFlyout();
    };

    return (
        <>
            <AddEditAccountForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitDisabled={submitValues !== null}
                organizations={organizations}
                brands={brands}
                accounts={accounts}
                isEdit={false}
            />
            <DialogBox
                open={submitValues !== null}
                onConfirm={onAddAccountSubmitProceed}
                onCancel={onSubmitAddAccountCancel}
                title="Add Account"
                message="Are you sure you want to add this account?"
                confirmText="Add Account"
            />
        </>
    );
}

AddAccountFlyout.propTypes = {
    createAccount: PropTypes.func.isRequired,
    closeFlyout: PropTypes.func.isRequired,
    organizations: PropTypes.array.isRequired,
    brands: PropTypes.array.isRequired,
    accounts: PropTypes.array,
};

export function mapDispatchToProps(dispatch) {
    return {
        createAccount: (values) => dispatch(addAccountAction(values)),
    };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(AddAccountFlyout);
