export const RadioData = {
    csBudgetRadioKey: 'csBudgetRadioKey',
    csMonthlyBudgetRadioKey: 'csMonthlyBudgetRadioKey',
    csDailyBudgetRadioKey: 'csDailyBudgetRadioKey',
    agBudgetRadioKey: 'agBudgetRadioKey',
    agMonthlyBudgetRadioKey: 'agMonthlyBudgetRadioKey',
    agDailyBudgetRadioKey: 'agDailyBudgetRadioKey',
    baseAndMaxBidRadioKey: 'baseAndMaxBidRadioKey',
};

export const TextFieldData = {
    csBudgetTextFieldKey: 'csBudgetTextFieldKey',
    csMonthlyBudgetTextFieldKey: 'csMonthlyBudgetTextFieldKey',
    csDailyBudgetTextFieldKey: 'csDailyBudgetTextFieldKey',
    agBudgetTextFieldKey: 'agBudgetTextFieldKey',
    agMonthlyBudgetTextFieldKey: 'agMonthlyBudgetTextFieldKey',
    agDailyBudgetTextFieldKey: 'agDailyBudgetTextFieldKey',
    baseAndMaxBidTextFieldKey: 'baseAndMaxBidTextFieldKey',
};

export const CampaignSettingsRadioData = [
    {
        radioTitleSection: {
            title: 'Campaign Budget',
            subTitle: null,
            name: 'campaignBudget',
            key: 'campaignBudgetKey',
        },
        radioGroup: [
            {
                label: 'Capped',
                subLabel: 'Your campaign will stop running once the budget is reached.',
                textField: true,
                textFieldKey: TextFieldData.csBudgetTextFieldKey,
                value: 'capped',
                name: 'campaignBudgetCapped',
                key: RadioData.csBudgetRadioKey,
            },
            {
                label: 'Uncapped',
                subLabel: 'Your campaign will spend freely from the funds available to it.',
                textField: false,
                textFieldKey: null,
                value: 'uncapped',
                name: 'campaignBudgetUncapped',
                key: RadioData.csBudgetRadioKey,
            },
        ],
    },
    {
        radioTitleSection: {
            title: 'Monthly Budget',
            subTitle: 'Limit how much your campaign can spend per month.',
            name: 'monthlyBudget',
            key: 'monthlyBudgetKey',
        },
        radioGroup: [
            {
                label: false,
                subLabel: false,
                textField: true,
                textFieldKey: TextFieldData.csMonthlyBudgetTextFieldKey,
                value: 'monthlyBudgetTextField',
                name: 'monthlyBudgetTextField',
                key: RadioData.csMonthlyBudgetRadioKey,
            },
            {
                label: 'None',
                subLabel: false,
                textField: false,
                textFieldKey: null,
                value: 'monthlyBudgetNone',
                name: 'monthlyBudgetNone',
                key: RadioData.csMonthlyBudgetRadioKey,
            },
        ],
    },
    {
        radioTitleSection: {
            title: 'Daily Budget',
            subTitle: 'Limit how much your campaign can spend per day.',
            name: 'dailyBudget',
            key: 'dailyBudgetKey',
        },
        radioGroup: [
            {
                label: 'Automated (Recommended)',
                subLabel: false,
                textField: false,
                textFieldKey: null,
                value: 'automated',
                name: 'dailyBudgetAutomated',
                key: RadioData.csDailyBudgetRadioKey,
            },
            {
                label: false,
                subLabel: false,
                textField: true,
                textFieldKey: TextFieldData.csDailyBudgetTextFieldKey,
                value: 'dailyBudgetTextfield',
                name: 'dailyBudgetTextfield',
                key: RadioData.csDailyBudgetRadioKey,
            },
            {
                label: 'None',
                subLabel: false,
                textField: false,
                textFieldKey: null,
                value: 'dailyBudgetNone',
                name: 'dailyBudgetNone',
                key: RadioData.csDailyBudgetRadioKey,
            },
        ],
    },
];

export const AdGroupSettingsRadioData = [
    {
        radioTitleSection: {
            title: 'Ad Group Budget',
            subTitle: null,
            name: 'adGroupBudget',
            key: 'adGroupBudgetKey',
        },
        radioGroup: [
            {
                label: 'Capped',
                subLabel: 'Your campaign will stop running once the budget is reached.',
                textField: true,
                textFieldKey: TextFieldData.agBudgetTextFieldKey,
                value: 'capped',
                name: 'adGroupBudgetCapped',
                key: RadioData.agBudgetRadioKey,
            },
            {
                label: 'Uncapped',
                subLabel: 'Your campaign will spend freely from the funds available to it.',
                textField: false,
                textFieldKey: null,
                value: 'uncapped',
                name: 'adGroupBudgetUncapped',
                key: RadioData.agBudgetRadioKey,
            },
        ],
    },
    {
        radioTitleSection: {
            title: 'Monthly Budget',
            subTitle: 'Limit how much your campaign can spend per month.',
            name: 'monthlyBudget',
            key: 'monthlyBudgetKey',
        },
        radioGroup: [
            {
                label: false,
                subLabel: false,
                textField: true,
                textFieldKey: TextFieldData.agMonthlyBudgetTextFieldKey,
                value: 'monthlyBudgetTextfield',
                name: 'monthlyBudgetTextfield',
                key: RadioData.agMonthlyBudgetRadioKey,
            },
            {
                label: 'None',
                subLabel: false,
                textField: false,
                textFieldKey: null,
                value: 'monthlyBudgetNone',
                name: 'monthlyBudgetNone',
                key: RadioData.agMonthlyBudgetRadioKey,
            },
        ],
    },
    {
        radioTitleSection: {
            title: 'Daily Budget',
            subTitle: 'Limit how much your campaign can spend per day.',
            name: 'dailyBudget',
            key: 'dailyBudgetKey',
        },
        radioGroup: [
            {
                label: 'Automated (Recommended)',
                subLabel: false,
                textField: false,
                textFieldKey: null,
                value: 'automated',
                name: 'dailyBudgetAutomated',
                key: RadioData.agDailyBudgetRadioKey,
            },
            {
                label: false,
                subLabel: false,
                textField: true,
                textFieldKey: TextFieldData.agDailyBudgetTextFieldKey,
                value: 'dailyBudgetTextfield',
                name: 'dailyBudgetTextfield',
                key: RadioData.agDailyBudgetRadioKey,
            },
            {
                label: 'None',
                subLabel: false,
                textField: false,
                textFieldKey: null,
                value: 'dailyBudgetNone',
                name: 'dailyBudgetNone',
                key: RadioData.agDailyBudgetRadioKey,
            },
        ],
    },
];

export const BaseAndMaxBidOptions = [
    {
        radioTitleSection: {
            title: null,
            subTitle: null,
            name: 'baseAndMaxBid',
            key: 'baseAndMaxBidKey',
        },
        radioGroup: [
            {
                label: "Don't Apply",
                subLabel: null,
                textField: false,
                textFieldKey: null,
                value: 'dontApply',
                name: 'dontApply',
                key: RadioData.baseAndMaxBidRadioKey,
            },
            {
                label: 'Apply Max Bid',
                subLabel: null,
                textField: false,
                textFieldKey: null,
                value: 'applyMaxBid',
                name: 'applyMaxBid',
                key: RadioData.baseAndMaxBidRadioKey,
            },
        ],
    },
];
