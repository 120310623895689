import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { textFieldFormatHandler } from '../CampaignManagementService';

const useStyles = makeStyles(() => ({
    textFieldMui: {
        '&.MuiFormControl-root': {
            maxWidth: '16.5rem',
            width: '100%',
            margin: '.5rem 0',
        },
        overflow: 'hidden',
    },
    dollarSign: {
        '& .MuiTypography-colorTextSecondary': {
            color: '#000000',
        },
        marginRight: 0,
    },
}));

export function NumberTextField({ option, radioSelections, textFieldValues, setTextFieldValues }) {
    const classes = useStyles();
    return (
        <TextField
            id="radio-label-subLabel-textField"
            className={classes.textFieldMui}
            disabled={radioSelections[option.key] !== option.value}
            variant="outlined"
            onKeyPress={(event) => {
                if (!/[0-9,]/.test(event.key)) {
                    event.preventDefault();
                }
            }}
            onChange={(e) => {
                textFieldFormatHandler(e.target.value, option.textFieldKey, textFieldValues, setTextFieldValues);
            }}
            InputProps={{
                inputMode: 'numeric',
                startAdornment: (
                    <InputAdornment
                        position="start"
                        classes={{
                            root: classes.dollarSign,
                        }}
                    >
                        $
                    </InputAdornment>
                ),
            }}
            value={textFieldValues[option.textFieldKey]}
        />
    );
}

NumberTextField.propTypes = {
    option: PropTypes.object,
    radioSelections: PropTypes.object,
    textFieldValues: PropTypes.object,
    setTextFieldValues: PropTypes.func,
};
