import React from 'react';
// import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    label: {
        height: 14,
        paddingBottom: '5px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
        whiteSpace: 'nowrap',
        marginTop: '1rem',
    },
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 6,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    optionsBelowRadial: {
        paddingTop: 0,
    },
    sectionContainer: {
        maxWidth: '42rem',
    },
    rowContainer: {
        paddingLeft: 0,
    },
    endDateField: {
        '&.MuiGrid-root': {
            maxWidth: '21rem',
            paddingRight: 0,
        },
    },
    optimizationRow: {
        '&.MuiGrid-root': {
            maxWidth: '21rem',
            paddingRight: '5rem',
            marginBottom: '1rem',
        },
    },
    productsSelected: {
        '&.MuiGrid-root': {
            maxWidth: '35rem',
            paddingRight: '5rem',
            marginBottom: '1rem',
        },
    },
}));

export function CampaignOptimizationBidStrategy() {
    const classes = useStyles();

    return (
        <div className={classes.sectionContainer}>
            <Typography
                variant="h5"
                component="h5"
                sx={{
                    fontWeight: 300,
                }}
            >
                Optimization & Bid Strategy
            </Typography>
            <Grid container className={classes.rowContainer}>
                <Grid item xs={4} className={classes.optimizationRow}>
                    <Typography variant="body1" className={classes.label}>
                        Optimization Strategy:
                    </Typography>
                    <Grid item className={classes.optionsBelowRadial}>
                        <InputLabel className={classes.inputLabel}>Clicks</InputLabel>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.endDateField}>
                    <Typography variant="body1" className={classes.label}>
                        CPC Bid:
                    </Typography>
                    <Grid item className={classes.optionsBelowRadial}>
                        <InputLabel className={classes.inputLabel}>$2.50-5.50</InputLabel>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.rowContainer}>
                <Grid item xs={4} className={classes.productsSelected}>
                    <Typography variant="body1" className={classes.label}>
                        Products Selected:
                    </Typography>
                    <Grid item className={classes.optionsBelowRadial}>
                        <InputLabel className={classes.inputLabel}>{5}</InputLabel>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.endDateField}>
                    <Typography variant="body1" className={classes.label}>
                        Keywords:
                    </Typography>
                    <Grid item className={classes.optionsBelowRadial}>
                        <InputLabel className={classes.inputLabel}>{8}</InputLabel>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

// CampaignOptimizationBidStrategy.propTypes = {};

export default CampaignOptimizationBidStrategy;
