/**
 *
 * TabPanel
 *
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import ErrorSvg from 'components/Svg/Error';
import CompleteSvg from 'components/Svg/Complete';
import { SvgIcon } from '@material-ui/core';
import TabContext from './TabSidebarContext';

function getKey({ value, label }) {
    if (value === undefined) {
        return label;
    }

    return value;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
    },
    tabs: {
        justifyContent: 'start',
        minWidth: '240px',
        maxWidth: '240px',
        backgroundColor: '#F0F2F4',
    },
    item: {
        height: 48,
        paddingLeft: '32px',
        color: theme.palette.techBlack,
        fontSize: '14px',
        fontWeight: theme.typography.fontWeightMedium,
        opacity: 1,
        backgroundColor: theme.palette.standardWhite,
        fill: theme.palette.humanBlue,
        textTransform: 'none',
        '&.active, &:hover, &.Mui-selected': {
            color: theme.palette.standardWhite,
            backgroundColor: theme.palette.humanBlue,
            '& path': {
                fill: theme.palette.standardWhite,
            },
        },
        '&.Mui-selected:hover': {
            backgroundColor: theme.palette.darkGray,
        },
        textAlign: 'left',
        '& span': {
            'align-items': 'flex-start',
        },
        borderTop: `1px solid ${theme.palette.divider}`,
        '&:first-child': {
            borderTop: 'none',
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'start',
        gap: '12px',
    },
    label: {
        minHeight: 48,
    },
}));

const getIcon = (itemStatus) => {
    switch (itemStatus) {
        case 'ERROR':
            return (
                <SvgIcon>
                    <ErrorSvg width={24} height={24} />
                </SvgIcon>
            );
        case 'COMPLETE':
            return (
                <SvgIcon>
                    <CompleteSvg width={24} height={24} />
                </SvgIcon>
            );
        default:
            return null;
    }
};

function SidebarTabView({ children, startingValue }) {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(startingValue);
    const prevStartingValue = useRef(startingValue);

    const handleChange = (event, value) => {
        setCurrentTab(value);
    };

    // Derived state: if startingValue updates externally, switch to the
    // new starting tab. This is necessary for things like URL navigation
    if (prevStartingValue.current !== startingValue) {
        prevStartingValue.current = startingValue;
        if (currentTab !== startingValue) {
            setCurrentTab(startingValue);
        }
    }

    return (
        <TabContext.Provider value={{ currentTab }}>
            <div className={classes.root}>
                <Tabs
                    orientation="vertical"
                    variant="fullWidth"
                    value={currentTab}
                    onChange={handleChange}
                    className={classes.tabs}
                    TabIndicatorProps={{ style: { background: 'transparent' } }}
                >
                    {React.Children.map(children, (child) => {
                        if (child.props.label) {
                            return (
                                <Tab
                                    label={child.props.label}
                                    icon={getIcon(child.props.itemStatus)}
                                    value={getKey(child.props)}
                                    classes={{ root: classes.item, wrapper: classes.wrapper, labelIcon: classes.label }}
                                />
                            );
                        }

                        return null;
                    })}
                </Tabs>
                {children}
            </div>
        </TabContext.Provider>
    );
}

SidebarTabView.propTypes = {
    children: PropTypes.node,
    startingValue: PropTypes.string.isRequired,
};

export { SidebarTabView };
