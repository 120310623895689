import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { moveAccountAction } from 'containers/OrganizationPage/slice';
import DialogBox from '../../../../components/DialogBox';
import MoveAccountForm from './MoveAccountForm';

export function MoveAccountFlyout({ moveAccount, organizations, brands, account, closeFlyout }) {
    const [submitValues, setSubmitValues] = useState(null);

    const onSubmit = (values) => {
        setSubmitValues(values);
    };

    const initialValues = useMemo(
        () => ({
            providerAccountOrgId: '',
            providerAccountOrgName: '',
            providerAccountName: account.providerAccountName || '',
            providerAccountId: account.providerAccountId || '',
        }),
        [account],
    );

    const onSubmitMoveAccountCancel = () => {
        setSubmitValues(null);
    };

    const onMoveAccountSubmitProceed = () => {
        if (submitValues) {
            const newOrgValues = {
                provider: account.providerName,
                id: account.id,
                providerAccountIdUpdated: account.providerAccountId,
                createdAt: account.createdAt || '',
                updatedAt: account.updatedAt || '',
                orgId: submitValues.organization[0].value,
                brandId: submitValues.myadsBrand[0].value,
                providerAccountName: submitValues.providerAccountName,
                providerAccountId: submitValues.providerAccountId,
            };
            moveAccount(newOrgValues);
            closeFlyout();
        }
    };

    return (
        <>
            <MoveAccountForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitDisabled={submitValues !== null}
                organizations={organizations}
                brands={brands}
                selectedItem={account}
            />
            <DialogBox
                open={submitValues !== null}
                onConfirm={onMoveAccountSubmitProceed}
                onCancel={onSubmitMoveAccountCancel}
                title="Move Account"
                message="Are you sure you want to move this account?"
                confirmText="Move Account"
            />
        </>
    );
}

MoveAccountFlyout.propTypes = {
    moveAccount: PropTypes.func,
    closeFlyout: PropTypes.func,
    organizations: PropTypes.array,
    brands: PropTypes.array,
    account: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
    moveAccount: (values) => dispatch(moveAccountAction(values)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(MoveAccountFlyout);
