import FormattedText from 'components/Formatting/FormattedText';
import React from 'react';
const { TableFormatter } = require('Helpers/table-formatter');

function createLegacyFormatter(formatFn) {
    return (value) => {
        const formattedValue = formatFn(value);
        return new TableFormatter({
            value,
            render: formattedValue,
        });
    };
}

function createColumnWithFormatter(formatFn, column) {
    return {
        applyFormatting: formatFn,
        format: createLegacyFormatter(formatFn),
        ...column,
    };
}

export function textColumn(field, label, options = {}) {
    return createColumnWithFormatter((value) => <FormattedText text={value} lineBreak={options.lineBreak} />, {
        field,
        label,
        type: 'string',
        ...options,
    });
}

export function numberColumn(field, label, options = {}) {
    const decimalFormat = Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return createColumnWithFormatter((value) => (value ? decimalFormat.format(value) : '0'), {
        field,
        label,
        type: 'decimal',
        ...options,
    });
}

export function currencyColumn(field, label, options = {}) {
    const currencyFormat = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return createColumnWithFormatter((value) => (value ? currencyFormat.format(value) : '$0.00'), {
        field,
        label,
        type: 'currency',
        ...options,
    });
}

export function imageColumn(field, label, options = {}) {
    return createColumnWithFormatter((value) => <img src={value.src} alt={value.description} />, {
        field,
        label,
        type: 'image',
        ...options,
    });
}
