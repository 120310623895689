/**
 *
 * AdGroupSettingsForm
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form';
import CampaignManagementRadioGroup from '../shared/CampaignManagementRadioGroup';
import { AdGroupSettingsRadioData, RadioData, TextFieldData } from '../CampaignManagementComponentData/RadioGroupData';
import { CampaignManagementNameField } from '../shared/CampaignManagementNameField';
import CampaignManagementDatePickerRange from '../shared/CampaignManagementDatePickerRange';
import OptimizationAndBiddingStrategy from './OptimizationAndBiddingStrategy/OptimizationAndBiddingStrategy';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        margin: '5px',
    },
    formButton: {
        maxWidth: '42rem',
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '5rem',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    content: {
        backgroundColor: 'white',
        padding: '2rem',
    },
    pageContainer: {
        width: 'auto',
    },
    leftGrid: {
        height: '100%',
    },
    gridSection: {
        maxWidth: '42rem',
    },
}));

// should be a prop when functional
const campaign = {
    campaignType: 'Sponsored Products',
    campaignName: 'FY25 Holiday Beats Studio Head Phones',
    campaignBalance: 20000,
    baseBid: 1.75,
    startDate: '2025-01-04',
    endDate: 'YYYY-MM-DD',
};

export function AdGroupSettingsForm({ loading }) {
    const classes = useStyles();

    const [radioSelections, setRadioSelections] = useState({
        [RadioData.agDailyBudgetRadioKey]: '',
        [RadioData.agMonthlyBudgetRadioKey]: '',
        [RadioData.agDailyBudgetRadioKey]: '',
    });

    const [textFieldValues, setTextFieldValues] = useState({
        [TextFieldData.agBudgetTextFieldKey]: '',
        [TextFieldData.agMonthlyBudgetTextFieldKey]: '',
        [TextFieldData.agDailyBudgetTextFieldKey]: '',
        [TextFieldData.baseAndMaxBidTextFieldKey]: 1.75,
    });

    return (
        <Grid container className={classes.pageContainer}>
            <Grid className={classes.leftGrid}>
                <Form
                    onSubmit={() => {}}
                    initialValues={campaign}
                    validate={(values) => {
                        const errors = {};
                        if (values.confirmPassword !== values.password) {
                            errors.confirmPassword = 'Confirm password must match.';
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} className={classes.content}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h2" component="h2" sx={{ fontWeight: 300 }}>
                                        Ad Group Settings
                                    </Typography>
                                    <br />
                                    <Grid item className={classes.gridSection}>
                                        <CampaignManagementNameField
                                            campaignName={campaign.campaignName}
                                            label="Ad Group Name"
                                            loading={loading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignManagementDatePickerRange dates={campaign} />
                                </Grid>
                                <Grid item xs={12}>
                                    <OptimizationAndBiddingStrategy
                                        textFieldValues={textFieldValues}
                                        setTextFieldValues={setTextFieldValues}
                                    />
                                    <CampaignManagementRadioGroup
                                        options={AdGroupSettingsRadioData}
                                        radioSelections={radioSelections}
                                        setRadioSelections={setRadioSelections}
                                        textFieldValues={textFieldValues}
                                        setTextFieldValues={setTextFieldValues}
                                        boldRadioLabel
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        data-testid="submit-campaign-setting-form-button"
                                        className={classes.formButton}
                                        disabled={false}
                                        type="submit"
                                    >
                                        <span>{submitting || loading ? 'Loading...' : 'Continue'}</span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            </Grid>
        </Grid>
    );
}

AdGroupSettingsForm.propTypes = {
    loading: PropTypes.bool,
};

export default AdGroupSettingsForm;
