/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 *
 * THIS IS A PUBLIC ROUTE if you need a route to be authed use PrivateRoute
 * A HOC around react-router-dom Route that renders the given route with an optional layout
 *
 * @param {node} component component to render on specified route
 * @param {node} layout layout to wrap rendered component in
 *
 * @returns {node} returns a route
 */
const withLayout = ({ component: Component, layout: Layout, layoutProps, ...rest }) => (
    <Route
        {...rest}
        render={(routeProps) =>
            Layout ? (
                <Layout {...layoutProps}>
                    <Component {...routeProps} />
                </Layout>
            ) : (
                <Component {...routeProps} />
            )
        }
    />
);

withLayout.propTypes = {
    component: PropTypes.elementType.isRequired,
    layout: PropTypes.elementType,
    layoutProps: PropTypes.object,
};

withLayout.defaultProps = {
    layout: false,
};

export default withLayout;
