import React from 'react';
import PropTypes from 'prop-types';
import { Fab, makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    fabButton: {
        backgroundColor: '#0146BE',
        color: '#ffffff',
        boxShadow: 'none',
        fontSize: '12px',
        textTransform: 'capitalize',
        '& .MuiSvgIcon-root': {
            margin: '0 5px 0 0',
            fontSize: 'medium',
        },
    },
}));

function AddButton({ onClick, label }) {
    const classes = useStyles();
    return (
        <Fab variant="extended" color="primary" size="medium" className={classes.fabButton} onClick={onClick}>
            <SvgIcon sx={{ margin: '0 20px', fontSize: 'small' }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                    color="#ffffff"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </SvgIcon>
            {label}
        </Fab>
    );
}

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default AddButton;
