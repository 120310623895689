import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    textValue: {
        height: 14,
        paddingBottom: '5px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
        whiteSpace: 'nowrap',
        '&.MuiFormLabel-root': {
            height: 6,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    optionsBelowRadial: {
        paddingTop: 0,
    },
}));

export function CampaignReviewName({ label, name }) {
    const classes = useStyles();
    return (
        <div>
            <Grid item className={classes.optionsBelowRadial}>
                <Typography variant="body1" className={classes.textValue}>
                    {label}
                </Typography>
            </Grid>
            <Grid item className={classes.optionsBelowRadial}>
                <InputLabel className={classes.textValue}>{name}</InputLabel>
            </Grid>
        </div>
    );
}

CampaignReviewName.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
};

export default CampaignReviewName;
