export const formatForUSCurrency = (number) =>
    new Intl.NumberFormat('en-US', {
        trailingZeroDisplay: 'stripIfInteger',
        style: 'currency',
        currency: 'USD',
    }).format(number);

export const onChangeRadioHandler = (value, option, radioSelections, setRadioSelections) => {
    // find key associated with field being changed
    const selectionKey = option.radioGroup[0].key;
    // make sure existing values go to state update
    const newRadioSelections = { ...radioSelections };
    // assign key updated value
    newRadioSelections[selectionKey] = value;
    setRadioSelections(newRadioSelections);
};

export const textFieldFormatHandler = (value, textFieldKey, textFieldValue, setTextFieldValue) => {
    // find key associated with field being changed
    const selectionKey = textFieldKey;
    // make sure existing values go to state update
    const newTextFieldValues = { ...textFieldValue };

    newTextFieldValues[selectionKey] = value;
    setTextFieldValue(newTextFieldValues);
};
