import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import VerticalMenu from 'components/@shared/VerticalMenu';
import { compact } from 'lodash';

const useStyles = makeStyles(({ palette, typography }) => ({
    actionMenu: {
        border: 'none !important',
        textAlign: 'left !important',

        '&:hover': {
            backgroundColor: `${palette.humanBlue} !important`,
            color: palette.standardWhite,
        },
    },
    noneActionMenu: {
        fontFamily: typography.fontFamily,
        fontSize: '14px',
        padding: '7px 20px',
        color: palette.shadowGray,
    },
}));

function ActionMenu({ actions, item }) {
    const classes = useStyles();
    return (
        <VerticalMenu
            buttons={compact(
                actions(item).map(
                    (action) =>
                        action &&
                        (action.onClick ? (
                            <Button
                                className={classes.actionMenu}
                                key={action.label}
                                aria-label={`${action.label} button`}
                                onClick={(event) => action.onClick(event, item)}
                            >
                                <span>{action.label}</span>
                            </Button>
                        ) : (
                            <div className={classes.noneActionMenu}>{action.label}</div>
                        )),
                ),
            )}
        />
    );
}
ActionMenu.propTypes = {
    actions: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
};

export default ActionMenu;
