import { downloadProximityReport, getProximityCampaignDetails } from 'api/api-client';
import { Permissions } from 'shared/constants/user/permissions';
import { CAMPAIGN_TYPE_PROXIMITY, PROXIMITY_KEY } from 'Helpers/helpers';
import { renderDefaultTooltipContent } from 'components/CampaignReporting/Header/tooltip-content.js/defaultTooltip';
import {
    columnMap,
    modifyColumns,
    PROXIMITY_TAB_DEFAULT_KEY,
    proximityCards,
    proximityTabs,
    proximityColumns,
    dzProximityOptions,
    proximitySheets,
    defaultColumns,
} from './index';
import { ResourceParam } from '../../../containers/ResourcePage';

export const proximityDefinitions = {
    label: 'Proximity',
    vehicleKey: PROXIMITY_KEY,
    campaignType: CAMPAIGN_TYPE_PROXIMITY,

    // Permissions
    viewPermissions: [Permissions.CAN_VIEW_PROXIMITY_CAMPAIGNS],
    downloadPermissions: [Permissions.CAN_DOWNLOAD_PROXIMITY_CAMPAIGNS],

    // Campaign Details Page
    detailsApiCall: getProximityCampaignDetails,
    useNewColumnFilter: false,
    defaultTabKey: PROXIMITY_TAB_DEFAULT_KEY,
    columnMap,
    cards: proximityCards,
    tabs: proximityTabs,
    columns: proximityColumns,
    modifyColumns,
    defaultColumns,
    visualizationOptions: dzProximityOptions,

    // Attribution
    tooltipRenderFn: renderDefaultTooltipContent,
    resourcesPageUrl: `/resources/${ResourceParam.PROXIMITY}`,
    attributionWindowDays: 14,

    // Download Report
    reportDownloadApiCall: downloadProximityReport,
    sheets: proximitySheets,
};
