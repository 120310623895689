import { makeStyles } from '@material-ui/core';
import { SidebarTab, SidebarTabView } from 'components/TabSidebar';
import React from 'react';
import { useParams } from 'react-router-dom';
import CreateProducts from './CreateProducts';
import CampaignReviewForm from '../CampaignManagement/CampaignReview/CampaignReviewForm';
import { CampaignSettingsForm } from '../CampaignManagement/CampaignSettings/CampaignSettingsForm';
import { AdGroupSettingsForm } from '../CampaignManagement/AdGroupSettings/AdGroupSettingsForm';

export const StepParam = {
    CAMPAIGN_SETTINGS: 'campaign-settings',
    AD_GROUP: 'ad-group',
    PRODUCTS: 'products',
    KEYWORDS: 'keywords',
    REVIEW: 'review',
};

const getTabValue = (tabParam) => {
    const tab = Object.values(StepParam).find((val) => val === tabParam?.toLowerCase());

    return tab || StepParam.CAMPAIGN_SETTINGS;
};

const useStyles = makeStyles(() => ({
    root: {
        padding: '3rem 0 3rem 0',
    },
    content: {
        width: '100%',
        minHeight: '600px',
        position: 'relative',
        top: '-3rem',
        backgroundColor: 'white',
    },
}));

function CreateSponsoredCampaign() {
    const { tab: tabParam } = useParams();

    const classes = useStyles();

    const tabValue = getTabValue(tabParam);
    return (
        <div className={classes.root}>
            <SidebarTabView startingValue={tabValue}>
                <SidebarTab
                    label="Campaign Settings"
                    value={StepParam.CAMPAIGN_SETTINGS}
                    itemStatus="COMPLETE"
                    pathname={`/create/sponsored/${StepParam.CAMPAIGN_SETTINGS}`}
                >
                    <CampaignSettingsForm />
                </SidebarTab>
                <SidebarTab
                    label="Ad Group"
                    value={StepParam.AD_GROUP}
                    itemStatus="ERROR"
                    pathname={`/create/sponsored/${StepParam.AD_GROUP}`}
                >
                    <AdGroupSettingsForm />
                </SidebarTab>
                <SidebarTab
                    label="Products"
                    value={StepParam.PRODUCTS}
                    pathname={`/create/sponsored/${StepParam.PRODUCTS}`}
                >
                    <div className={classes.content}>
                        <CreateProducts />
                    </div>
                </SidebarTab>
                <SidebarTab
                    label="Keywords"
                    value={StepParam.KEYWORDS}
                    pathname={`/create/sponsored/${StepParam.KEYWORDS}`}
                >
                    <div className={classes.content}>
                        <h1>Keywords</h1>
                    </div>
                </SidebarTab>
                <SidebarTab label="Review" value={StepParam.REVIEW} pathname={`/create/sponsored/${StepParam.REVIEW}`}>
                    <div className={classes.content}>
                        <CampaignReviewForm />
                    </div>
                </SidebarTab>
            </SidebarTabView>
        </div>
    );
}

export default CreateSponsoredCampaign;
