import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { Button, Grid, FormControl, InputLabel } from '@material-ui/core';
import {
    required,
    isNotBlank,
    isNotWhitespace,
    minLengthNoWhitespace,
    composeValidators,
    maxLength,
} from 'utils/validators';
import MultiCheckSelect from '../../../../components/MultiCheckSelect';
import TextDisabled from '../../../../components/TextDisabled/Loadable';
import { comparator } from '../../../../utils/sortComparator';
import Input from '../../../../components/Input';
import { VALIDATE_ON_MODIFIED } from '../../../../utils/constants';
import { findDuplicateFormValues } from '../../utils';

const useStyles = makeStyles((theme) => ({
    submitButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
        '&:hover:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    inputLabel: {
        height: 14,
        paddingBottom: '12px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    fieldContainer: {
        paddingBottom: '36px',
    },
}));

function AddEditAccountForm({
    onSubmit,
    submitDisabled,
    initialValues,
    organizations = [],
    brands = [],
    accounts = [],
    isEdit,
}) {
    const classes = useStyles();

    const orgOptions = organizations
        .slice()
        .sort((a, b) => comparator(a, b))
        .map((org) => ({ name: org.name, value: org.orgId }));

    const brandOptions = brands
        .slice()
        .sort((a, b) => comparator(a, b))
        .map((brand) => ({ name: brand.name, value: brand.id }));

    const providerSelectData = [
        { name: 'Salesforce Sales Cloud', value: 'Salesforce Sales Cloud' },
        { name: 'Salesforce Media Cloud', value: 'Salesforce Media Cloud' },
    ];

    return (
        <>
            <Form
                keepDirtyOnReinitialize
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => (
                    <form onSubmit={handleSubmit}>
                        {!isEdit && (
                            <>
                                <Grid item xs={12}>
                                    <Field
                                        name="organization"
                                        component="select"
                                        type="text"
                                        validate={composeValidators(required, isNotBlank)}
                                    >
                                        {({ input, meta }) => (
                                            <Grid item xs={12} className={classes.fieldContainer}>
                                                <InputLabel className={classes.inputLabel}>Organization</InputLabel>
                                                <div>
                                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                                        <MultiCheckSelect
                                                            {...input}
                                                            multiple={false}
                                                            checkbox={false}
                                                            placeholder="Search Organizations"
                                                            values={input.value || []}
                                                            options={orgOptions}
                                                            onBlur={(event) => input.onBlur(event)}
                                                            onChange={(newValue) => {
                                                                input.onChange(newValue);
                                                            }}
                                                            noOptionsText="No Options available"
                                                        />
                                                    </FormControl>
                                                    {meta.error && meta.submitFailed && (
                                                        <span className={classes.error}>
                                                            Please select an organization type.
                                                        </span>
                                                    )}
                                                </div>
                                            </Grid>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="myadsBrand"
                                        component="select"
                                        type="text"
                                        validate={composeValidators(required, isNotBlank)}
                                    >
                                        {({ input, meta }) => (
                                            <Grid item xs={12} className={classes.fieldContainer}>
                                                <InputLabel className={classes.inputLabel}>Brand</InputLabel>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <MultiCheckSelect
                                                        {...input}
                                                        multiple={false}
                                                        checkbox={false}
                                                        placeholder="Search Brands"
                                                        values={input.value || []}
                                                        options={brandOptions}
                                                        onBlur={(event) => input.onBlur(event)}
                                                        onChange={(newValue) => {
                                                            input.onChange(newValue);
                                                        }}
                                                        noOptionsText="No Options available"
                                                    />
                                                </FormControl>
                                                {meta.error && meta.submitFailed && (
                                                    <span className={classes.error}>Please select a brand.</span>
                                                )}
                                            </Grid>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} className={classes.fieldContainer}>
                                    <Field
                                        name="providerName"
                                        component="select"
                                        type="text"
                                        validate={composeValidators(required, isNotBlank)}
                                    >
                                        {({ input, meta }) => (
                                            <Grid item xs={12}>
                                                <InputLabel className={classes.inputLabel}>Provider</InputLabel>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <MultiCheckSelect
                                                        {...input}
                                                        multiple={false}
                                                        checkbox={false}
                                                        placeholder="Select Provider"
                                                        values={input.value || []}
                                                        options={providerSelectData}
                                                        onBlur={(event) => input.onBlur(event)}
                                                        onChange={(newValue) => {
                                                            input.onChange(newValue);
                                                        }}
                                                        noOptionsText="No Options available"
                                                    />
                                                </FormControl>
                                                {meta.error && meta.submitFailed && (
                                                    <span className={classes.error}>Please select a provider.</span>
                                                )}
                                            </Grid>
                                        )}
                                    </Field>
                                </Grid>
                            </>
                        )}
                        {isEdit && (
                            <Grid item xs={12} className={classes.fieldContainer}>
                                <InputLabel className={classes.inputLabel}>Provider</InputLabel>
                                <TextDisabled text={initialValues.providerName} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Field
                                name="providerAccountName"
                                component="input"
                                type="text"
                                validate={composeValidators(
                                    required,
                                    isNotBlank,
                                    isNotWhitespace,
                                    minLengthNoWhitespace(2),
                                    maxLength(50),
                                    findDuplicateFormValues(
                                        initialValues,
                                        accounts,
                                        'providerAccountName',
                                        'name',
                                        'Account',
                                    ),
                                )}
                            >
                                {(props) => (
                                    <Input
                                        inputLabel={isEdit ? 'Account Name' : 'Salesforce Account Name'}
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        inputProps={{
                                            'data-testid': 'provider-account-name-input',
                                            'aria-label': 'providerAccountName',
                                            maxLength: 255,
                                            id: 'provider-account-name',
                                        }}
                                        validateOn={VALIDATE_ON_MODIFIED}
                                        {...props}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="providerAccountId"
                                component="input"
                                type="text"
                                validate={composeValidators(
                                    required,
                                    isNotBlank,
                                    isNotWhitespace,
                                    findDuplicateFormValues(
                                        initialValues,
                                        accounts,
                                        'providerAccountId',
                                        'ID',
                                        'Account',
                                    ),
                                )}
                            >
                                {(props) => (
                                    <Input
                                        inputLabel={isEdit ? 'Account ID' : 'Salesforce Case Safe Account ID'}
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        inputProps={{
                                            'data-testid': 'provider-account-id-input',
                                            'aria-label': 'providerAccountId',
                                            maxLength: 255,
                                            id: 'provider-account-id',
                                        }}
                                        validateOn={VALIDATE_ON_MODIFIED}
                                        {...props}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Button
                            data-testid="submit-add-edit-account-form-button"
                            className={classes.submitButton}
                            type="submit"
                            disabled={submitting || hasValidationErrors || pristine || submitDisabled}
                        >
                            {isEdit ? 'Update' : 'Add Account'}
                        </Button>
                    </form>
                )}
            />
        </>
    );
}

AddEditAccountForm.propTypes = {
    onSubmit: PropTypes.func,
    submitDisabled: PropTypes.bool,
    initialValues: PropTypes.object,
    organizations: PropTypes.array,
    brands: PropTypes.array,
    accounts: PropTypes.array,
    isEdit: PropTypes.bool,
};

export default AddEditAccountForm;
