import React from 'react';

/*
Resource Proximity is only half done, but will be
continued in the future once PMs get more details.
*/
export const ResourceProximity = () => (
    <>
        <br />
        <br />
        <h3>Store.Ads - Proximity</h3>
        <br />
        <p>
            Proximity messaging allows Best Buy Ads partners to reach Best Buy app users, our most loyal customer base,
            right where they are through proprietary mobile location technology within our many store locations.
        </p>
        <br />
        <p>
            In-store proximity messaging leverages beacon technology to recognize when shoppers are entering our store
            and meet specific messaging criteria that will allow us to engage them with a push notification and full
            screen interstitial ad experience.
        </p>
    </>
);
