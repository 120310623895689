/**
 *
 * CampaignReviewForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CampaignReviewBudget } from './CampaignReviewBudget';
import { CampaignReviewName } from './CampaignReviewName';
import { CampaignReviewSchedule } from './CampaignReviewSchedule';
import { CampaignOptimizationBidStrategy } from './CampaignOptimizationBidStrategy';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        margin: '5px',
    },
    publishButtonContainer: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    saveDraftButtonContainer: {
        maxWidth: '11rem',
        padding: '0 1rem',
        marginRight: '1rem',
        height: 42,
        borderRadius: 4,
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.standardWhite,
        borderColor: theme.palette.humanBlue,
        color: theme.palette.humanBlue,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
            color: theme.palette.humanBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    content: {
        backgroundColor: 'white',
        padding: '2rem',
    },
    pageContainer: {
        width: 'auto',
    },
    leftGrid: {
        height: '100%',
    },
    gridSection: {
        maxWidth: '42rem',
    },
    balanceLabel: {
        '&.MuiFormLabel-root': {
            height: 14,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    budgetSection: {
        flexDirection: 'row',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: '2rem',
        width: '100%',
        maxWidth: '42rem',
    },
    buttonDraft: {
        width: '100%',
        maxWidth: '12rem',
    },
    buttonPublish: {
        width: '100%',
        maxWidth: '28rem',
    },
}));

// should be a prop when functional
const campaign = {
    campaignType: 'Sponsored Products',
    names: {
        campaignName: 'FY25 Holiday Test Campaign name',
        adGroupName: 'FY25 Holiday Ad group name',
    },
    campaignBalance: 20000,
    startDate: '2022-04-17',
    endDate: '2023-05-18',
    campaignBudgets: {
        campaignBudget: [
            {
                label: 'Campaign Capped Budget',
                budgetAmount: 5000,
            },
            {
                label: 'Remaining Budget',
                budgetAmount: 15000,
            },
            {
                label: 'Available Budget',
                budgetAmount: 20000,
            },
        ],
        adGroupBudget: [
            {
                label: 'Ad Group Budget',
                budgetAmount: 2500,
            },
            {
                label: 'Monthly Budget',
                budgetAmount: 500,
            },
            {
                label: 'Daily Budget',
                budgetAmount: 150,
            },
        ],
    },
    OptAndBid: {
        optStrategy: 'Clicks',
        cpcBid: [2.5, 5.5],
        productsSelected: 5,
        keywords: 8,
    },
};

export function CampaignReviewForm({ loading }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.pageContainer}>
            <Grid className={classes.leftGrid}>
                <Form
                    onSubmit={() => {}}
                    initialValues={campaign}
                    validate={(values) => {
                        const errors = {};
                        if (values.confirmPassword !== values.password) {
                            errors.confirmPassword = 'Confirm password must match.';
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} className={classes.content}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h5" sx={{ fontWeight: 300 }}>
                                        Summary Review
                                    </Typography>
                                    <br />
                                    <Grid item className={classes.gridSection}>
                                        <CampaignReviewName label="Campaign Name" name={campaign.names.campaignName} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignReviewBudget budget={campaign.campaignBudgets.campaignBudget} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignReviewSchedule
                                        header="Schedule"
                                        startDate={campaign.startDate}
                                        endDate=""
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignReviewName label="Ad Group Name" name={campaign.names.adGroupName} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignReviewBudget budget={campaign.campaignBudgets.adGroupBudget} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignReviewSchedule
                                        header="Ad Group Schedule"
                                        startDate={campaign.startDate}
                                        endDate={campaign.endDate}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignOptimizationBidStrategy OptAndBid={campaign.OptAndBid} />
                                </Grid>
                                <div className={classes.buttonContainer}>
                                    <Grid className={classes.buttonDraft}>
                                        <Button
                                            data-testid="submit-campaign-setting-form-button"
                                            className={classes.saveDraftButtonContainer}
                                            disabled={false}
                                            variant="outlined"
                                            type="submit"
                                        >
                                            <span>Save Draft</span>
                                        </Button>
                                    </Grid>
                                    <Grid className={classes.buttonPublish}>
                                        <Button
                                            data-testid="submit-campaign-setting-form-button"
                                            className={classes.publishButtonContainer}
                                            disabled={false}
                                            type="submit"
                                        >
                                            <span>{submitting || loading ? 'Loading...' : 'Publish Campaign'}</span>
                                        </Button>
                                    </Grid>
                                </div>
                            </Grid>
                        </form>
                    )}
                />
            </Grid>
        </Grid>
    );
}

CampaignReviewForm.propTypes = {
    loading: PropTypes.bool,
};

export default CampaignReviewForm;
