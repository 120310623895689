import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import RadioSelect from '../../../../components/RadioSelect';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 14,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    dropdown: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        zIndex: 1,
        border: '1px solid #ccc',
        backgroundColor: '#fff',
        boxShadow: theme.shadows[3],
        padding: theme.spacing(1),
    },
    radio: {
        color: theme.palette.primary.main,
        '&.Mui-checked': {
            color: theme.palette.primary.main,
        },
    },
}));

// eslint-disable-next-line no-unused-vars
export function OptimizationStrategyFormControlSelect({ label, options }) {
    const classes = useStyles();

    const [selectValue, setSelectValue] = useState('');

    return (
        <div>
            <InputLabel className={classes.inputLabel}>{label}</InputLabel>
            <FormControl margin="dense" variant="outlined" fullWidth>
                <RadioSelect
                    value={selectValue}
                    placeholder="Choose Strategy"
                    options={options}
                    onChange={(newValue) => {
                        setSelectValue(newValue);
                    }}
                    noOptionsText="No Options available"
                    customDropdownIcon
                />
            </FormControl>
        </div>
    );
}

OptimizationStrategyFormControlSelect.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
};

export default OptimizationStrategyFormControlSelect;
