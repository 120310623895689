import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 14,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    selectContainer: {
        display: 'flex',
    },
    leftSelect: {
        maxWidth: '15rem',
        width: '100%',
        marginRight: '1rem',
    },
    rightSelect: {
        maxWidth: '26rem',
        width: '100%',
        marginRight: 0,
    },
}));

// eslint-disable-next-line no-unused-vars
export function CampaignSettingsAttributionFormControlSelect({ label, options, id, isLeft }) {
    const classes = useStyles();

    const [selectValue, setSelectValue] = useState(options[1].value);

    return (
        <div className={isLeft ? classes.leftSelect : classes.rightSelect}>
            <InputLabel className={classes.inputLabel}>{label}</InputLabel>
            <Select
                labelId={id}
                id={id}
                value={selectValue}
                variant="outlined"
                autoWidth
                className={classes.selectContainer}
                onChange={(e) => {
                    setSelectValue(e.target.value);
                }}
            >
                {options &&
                    options.map((option) => (
                        <MenuItem value={option.value} key={`${option.value}`}>
                            {option.name}
                        </MenuItem>
                    ))}
            </Select>
        </div>
    );
}

CampaignSettingsAttributionFormControlSelect.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    id: PropTypes.string,
    isLeft: PropTypes.bool,
};

export default CampaignSettingsAttributionFormControlSelect;
