export const AttributionSettingsData = {
    postClickSettingsOptions: [
        { name: 'Lookback Window 7 Days', value: '7' },
        { name: 'Lookback Window 14 Days', value: '14' },
        { name: 'Lookback Window 30 Days', value: '30' },
    ],
    postViewSettingsOptions: [
        { name: 'Lookback Window No Post-view', value: '0' },
        { name: 'Lookback Window 7 Days', value: '7' },
        { name: 'Lookback Window 14 Days', value: '14' },
        { name: 'Lookback Window 30 Days', value: '30' },
    ],
    productMatchOptions: [
        { name: 'Same Product (default)', value: 'Same Product (default)' },
        { name: 'Same Product, Same Category', value: 'Same Product, Same Category,' },
        {
            name: 'Same Product, Same Category, Same Brand (Default)',
            value: 'Same Product, Same Category, Same Brand (Default)',
        },
    ],
};
