import React from 'react';
import OptimizationStrategyFormControlSelect from './OptimizationStrategyFormControlSelect';

export function OptimizationStrategy() {
    const optimizationStrategy = [
        { name: 'Clicks', value: 'Clicks' },
        { name: 'Impressions', value: 'Impressions' },
        { name: 'CTR', value: 'CTR' },
        { name: 'Delivered Spend', value: 'Delivered Spend' },
    ];

    return <OptimizationStrategyFormControlSelect label="Optimization Strategy" options={optimizationStrategy} />;
}

OptimizationStrategy.propTypes = {};

export default OptimizationStrategy;
