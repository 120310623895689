import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BaseAndMaxBid from './BaseAndMaxBid';
import OptimizationStrategy from './OptimizationStrategy';

const useStyles = makeStyles(() => ({
    sectionContainer: {
        maxWidth: '42rem',
    },
    gridSection: {
        maxWidth: '22rem',
    },
}));

const OptimizationAndBiddingStrategy = ({ textFieldValues, setTextFieldValues }) => {
    const classes = useStyles();

    return (
        <div className={classes.sectionContainer}>
            <Typography
                variant="h5"
                component="h5"
                sx={{
                    fontWeight: 300,
                }}
            >
                Optimization and Bidding Strategy
            </Typography>
            <Grid container direction="column" spacing={2}>
                <Grid item className={classes.gridSection}>
                    <OptimizationStrategy />
                </Grid>
                <Grid item className={classes.gridSection}>
                    <BaseAndMaxBid textFieldValues={textFieldValues} setTextFieldValues={setTextFieldValues} />
                </Grid>
            </Grid>
        </div>
    );
};

OptimizationAndBiddingStrategy.propTypes = {
    textFieldValues: PropTypes.object,
    setTextFieldValues: PropTypes.func,
};

export default OptimizationAndBiddingStrategy;
