export function useSponsoredCreation() {
    const products = [
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            sku: '12345',
            color: 'Black',
            upc: '123456789012',
            model: 'ABC123',
            status: 'In Stock',
            bid: 1.25,
            price: 100,
        },
    ];

    return { products };
}
