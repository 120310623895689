import React from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import ActionMenu from 'components/ActionMenu';

function LightweightTable({ columns, data, actions }) {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.field + column.label}>{column.label}</TableCell>
                        ))}
                        {actions && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            {columns.map((column) => (
                                <TableCell key={column.field + column.label}>
                                    <div className={column.cellClassName}>
                                        {column.applyFormatting(row[column.field])}
                                    </div>
                                </TableCell>
                            ))}
                            <TableCell>
                                <ActionMenu actions={actions} item={row} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

LightweightTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.func,
};

export default LightweightTable;
