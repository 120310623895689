import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
    singleLine: {
        whiteSpace: 'nowrap',
        '@media (max-width: 768px)': {
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '165px',
        },
    },
}));

function FormattedText({ text = '', lineBreak = false }) {
    const classes = useStyles();
    return <span className={lineBreak ? undefined : classes.singleLine}>{text}</span>;
}

FormattedText.propTypes = {
    text: PropTypes.string.isRequired,
    lineBreak: PropTypes.bool,
};

export default FormattedText;
