import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CampaignManagementRadioOption from './CampaignManagementRadioOption';
import { onChangeRadioHandler } from '../CampaignManagementService';

const useStyles = makeStyles((theme) => ({
    subTitle: {
        height: 14,
        padding: '10px 0px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    option: {
        '&.MuiFormLabel-root': {
            height: 14,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    optionInput: {
        '&.MuiFormLabel-root': {
            margin: '1rem 0',
        },
    },
    group: {
        marginBottom: '1rem',
        marginTop: '.5rem',
    },
}));

export function CampaignManagementRadioGroup({
    options,
    radioSelections,
    boldRadioLabel,
    setRadioSelections,
    textFieldValues,
    setTextFieldValues,
}) {
    const classes = useStyles();

    return options.map((option) => (
        <div key={option.radioTitleSection.key} className={classes.group}>
            <Typography
                variant="h5"
                component="h5"
                sx={{
                    fontWeight: 300,
                }}
            >
                {option.radioTitleSection.title}
            </Typography>
            {option.radioTitleSection.subTitle && (
                <InputLabel className={classes.subTitle}>{option.radioTitleSection.subTitle}</InputLabel>
            )}
            <Grid
                container
                direction="column"
                sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}
            >
                {option.radioGroup.map((radioOption, index) => (
                    <RadioGroup
                        onChange={(event) =>
                            onChangeRadioHandler(event.target.value, option, radioSelections, setRadioSelections)
                        }
                        key={option.radioTitleSection.key + option.radioGroup[index].name}
                    >
                        <CampaignManagementRadioOption
                            radioOption={radioOption}
                            radioSelections={radioSelections}
                            boldRadioLabel={boldRadioLabel}
                            textFieldValues={textFieldValues}
                            setTextFieldValues={setTextFieldValues}
                        />
                    </RadioGroup>
                ))}
                <br />
            </Grid>
        </div>
    ));
}

CampaignManagementRadioGroup.propTypes = {
    options: PropTypes.array,
    radioSelections: PropTypes.object,
    setRadioSelections: PropTypes.func,
    boldRadioLabel: PropTypes.bool,
    textFieldValues: PropTypes.object,
    setTextFieldValues: PropTypes.func,
};

export default CampaignManagementRadioGroup;
