import request, {
    formatPostOptions as postOptions,
    formatGetOptions as getOptions,
    formatPutOptions as putOptions,
} from 'utils/request';

import { formatUpdatedUser, formatUpdatedUserBrands, formatUser } from './api-service/maps';
import { BASE_URL_V2, BASE_URL_V5 } from './api-service/Url_Constants';

export const INVITE_SENT = 'Invite Sent';
export const EMAIL_CODE_INVALID = 'Code Invalid';
export const EMAIL_CODE_EXPIRED = 'Code Expired';
export const EMAIL_CODE_ACCEPTED = 'Email Code Accepted';
export const PHONE_CODE_ACCEPTED = 'Phone Code Accepted';
export const PASSWORD_SET = 'Password Set';
export const USER_UPDATED = 'User Updated';

/**
 * Add a new user
 *
 * @param {object} payload  Object details object
 * @example
 * {
 *  "first_name": "First",
 *  "last_name": "Last",
 *  "email": "first.last@bestbuy.com",
 *  "aid": "A1234567",
 *  "roles": ["Internal_Developer"]
 *  "brandIds": []
 * }
 * @returns {object} response data or error based upon status
 */
export const userAddApi = ({ first_name, last_name, email, aid, roles, brandIds, organization }) => {
    const options = postOptions({
        user: {
            firstName: first_name,
            lastName: last_name,
            email,
            aid,
            state: 'Active',
            roleNames: roles,
            orgId: organization.id,
            brandIds,
        },
    });

    if (options?.data.user.orgId === null) {
        delete options.data.user.orgId;
    }

    return request(`${BASE_URL_V5}/users/addUser`, options);
};

/**
 * Update current user
 *
 * @param {object} user  Object details object
 * @example
 * {
 *  "uuid" : "006ec4ba462e89c567478f29ddf446fa"
 *  "first_name": "First",
 *  "last_name": "Last",
 *  "roles": ["Internal_Developer"]
 * }
 * @returns {object} response data or error based upon status
 */
export const userUpdateApi = async (user) => {
    const { uuid, first_name, last_name, email, roles, active, organization, aid } = user;
    const options = putOptions({
        user: {
            userId: uuid,
            orgId: organization.id,
            firstName: first_name,
            lastName: last_name,
            email,
            state: active ? 'Active' : 'Inactive',
            roleNames: roles,
            aid,
        },
    });

    if (options?.data.user.orgId === null) {
        delete options.data.user.orgId;
    }

    const response = await request(`${BASE_URL_V5}/users/update`, options);

    return formatUpdatedUser(response.payload.updatedUser);
};

/**
 * Update user brands
 *
 * @param {object} user  Object details object
 * @example
 * {
 *  "uuid" : "006ec4ba462e89c567478f29ddf446fa"
 *  "first_name": "First",
 *  "last_name": "Last",
 *  "roles": ["Internal_Developer"]
 * }
 * @returns {object} response data or error based upon status
 */

export const updateUserBrands = async (user, brandsToUpdate) => {
    const { uuid, organization } = user;
    const { brandsToAdd, brandsToRemove } = brandsToUpdate;
    const options = postOptions({
        userId: uuid,
        orgId: organization.id,
        brandsToAdd,
        brandsToRemove,
    });

    const result = await request(`${BASE_URL_V5}/users/updateUserBrands`, options);

    return formatUpdatedUserBrands(result.payload.updatedUser);
};

/*
 * @returns {object} response data or error based upon status
 */
export const userListApi = async () => {
    const data = await request(`${BASE_URL_V5}/users/getAllUsers`, getOptions());

    const users = data?.payload?.userList || [];

    const formattedUsers = users.map(formatUser).reverse();

    return formattedUsers;
};

/**
 * Update user password
 *
 * @param {object} payload  Object details object
 * @example
 * {
 *  "uuid": "006ec4ba462e89c567478f29ddf446fa",
 *  "password": "newpassword",
 * }
 * @returns {object} response data or error based upon status
 */
export const userPasswordUpdateApi = ({ userId, password }) => {
    const options = putOptions({
        userId,
        password,
    });

    return request(`${BASE_URL_V5}/users/updatePassword`, options);
};

/**
 * reinvite user
 *
 * @returns {object} response data or error based upon status
 */
export const userReinviteApi = (user) => {
    const payload = {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        userId: user.uuid,
    };
    const options = postOptions({ user: payload });

    return request(`${BASE_URL_V5}/users/reinvite`, options);
};

/**
 * invite user during registration
 *
 * @returns {object} response data or error based upon status
 */

export const inviteUser = async (inviteId) => {
    const options = getOptions();

    const resp = await request(`${BASE_URL_V2}/idam/ui/inviteUser/${inviteId}`, options);

    return resp?.payload;
};

export const phoneOTPRequest = async (phoneNumber) => {
    const options = postOptions({
        mobile: phoneNumber,
    });

    const { payload } = await request(`${BASE_URL_V2}/idam/ui/startOfflinePairing?mode=SMS`, options);

    return payload;
};

export const checkOTP = async ({ code, pairingSession }) => {
    const options = postOptions({
        otp: code,
        sessionId: pairingSession,
    });

    try {
        await request(`${BASE_URL_V2}/idam/ui/finalizeOfflinePairing`, options);
    } catch (e) {
        const status = e.response?.data?.payload?.status;
        if (status === 532) {
            return EMAIL_CODE_INVALID;
        }
        if (status === 517) {
            return EMAIL_CODE_EXPIRED;
        }
        throw e;
    }

    return EMAIL_CODE_ACCEPTED;
};

export const updateIDAMUserInfo = async (userInfo) => {
    const options = postOptions({
        requestId: userInfo.requestId,
        email: userInfo.email,
        firstName: userInfo.first,
        lastName: userInfo.last,
        phone: userInfo.phone,
    });

    const { responseCode } = await request(`${BASE_URL_V2}/idam/ui/updateUser`, options);

    return responseCode === '200 OK';
};

export const setIDAMUserPassword = async ({ email, password }) => {
    const options = postOptions({
        email,
        password,
    });

    const { responseCode } = await request(`${BASE_URL_V2}/idam/ui/setPassword`, options);

    return responseCode === '200 OK';
};

export const getIDAMUserInfo = async () => {
    const options = getOptions();

    const { payload } = await request(`${BASE_URL_V2}/idam/ui/getSessionUser`, options);

    return {
        uuid: payload.user?.uuid,
        first: payload.user?.first_name,
        last: payload.user?.last_name,
        email: payload.user?.email,
    };
};

export const getRegistrationStatus = async (userId) => {
    const options = getOptions();
    const { payload } = await request(`${BASE_URL_V2}/idam/ui/inviteStatus/getInviteById?userId=${userId}`, options);
    switch (payload?.invite_state) {
        case 'OTP_INVITE_ACCEPTED':
            return EMAIL_CODE_ACCEPTED;
        case 'OTP_PHONE_ACCEPTED':
            return PHONE_CODE_ACCEPTED;
        case 'OTP_PASSWORD_ACCEPTED':
            return PASSWORD_SET;
        case 'INVITE_PROFILE_UPDATED':
            return USER_UPDATED;
        default:
            return INVITE_SENT;
    }
};
