import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { Button, Grid, FormControl, InputLabel } from '@material-ui/core';
import { required, isNotBlank, composeValidators } from 'utils/validators';
import MultiCheckSelect from '../../../../components/MultiCheckSelect';
import TextDisabled from '../../../../components/TextDisabled/Loadable';
import { comparator } from '../../../../utils/sortComparator';

const useStyles = makeStyles((theme) => ({
    submitButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
        '&:hover:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    inputLabel: {
        height: 14,
        paddingBottom: '12px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
    fieldContainer: {
        paddingBottom: '12px',
    },
}));

function MoveAccountForm({ onSubmit, submitDisabled, initialValues, selectedItem, organizations, brands }) {
    const classes = useStyles();

    const orgOptions = organizations
        .slice()
        .sort((a, b) => comparator(a, b))
        .map((org) => ({ name: org.name, value: org.orgId }));

    const brandOptions = brands
        .slice()
        .sort((a, b) => comparator(a, b))
        .map((brand) => ({ name: brand.name, value: brand.id }));

    return (
        <>
            <Form
                keepDirtyOnReinitialize
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.fieldContainer}>
                                <InputLabel className={classes.inputLabel}>Organization</InputLabel>
                                <TextDisabled text={selectedItem.providerAccountOrgName} />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldContainer}>
                                <InputLabel className={classes.inputLabel}>Salesforce Account Name</InputLabel>
                                <TextDisabled text={selectedItem.providerAccountName} />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldContainer}>
                                <InputLabel className={classes.inputLabel}>Salesforce Account ID</InputLabel>
                                <TextDisabled text={selectedItem.providerAccountId} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="myadsBrand"
                                    component="select"
                                    type="text"
                                    validate={composeValidators(required, isNotBlank)}
                                >
                                    {({ input, meta }) => (
                                        <Grid item xs={12} className={classes.fieldContainer}>
                                            <InputLabel className={classes.inputLabel}>Move to Brand Name</InputLabel>
                                            <FormControl margin="dense" variant="outlined" fullWidth>
                                                <MultiCheckSelect
                                                    multiple={false}
                                                    checkbox={false}
                                                    placeholder="Search Brands..."
                                                    values={input.value || []}
                                                    options={brandOptions}
                                                    onBlur={(event) => input.onBlur(event)}
                                                    onChange={(newValue) => {
                                                        input.onChange(newValue);
                                                    }}
                                                    noOptionsText="No Options available"
                                                />
                                            </FormControl>
                                            {meta.error && meta.submitFailed && (
                                                <span className={classes.error}>Please select a brand.</span>
                                            )}
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="organization"
                                    component="select"
                                    type="text"
                                    validate={composeValidators(required, isNotBlank)}
                                >
                                    {({ input, meta }) => (
                                        <Grid item xs={12} className={classes.fieldContainer}>
                                            <InputLabel className={classes.inputLabel}>Move to Organization</InputLabel>
                                            <FormControl margin="dense" variant="outlined" fullWidth>
                                                <MultiCheckSelect
                                                    id="moveToOrganization"
                                                    multiple={false}
                                                    checkbox={false}
                                                    placeholder="Search Organizations..."
                                                    values={input.value || []}
                                                    options={orgOptions}
                                                    onBlur={(event) => input.onBlur(event)}
                                                    onChange={(newValue) => {
                                                        input.onChange(newValue);
                                                    }}
                                                    noOptionsText="No Options available"
                                                />
                                            </FormControl>
                                            {meta.error && meta.submitFailed && (
                                                <span className={classes.error}>Please select an organization.</span>
                                            )}
                                        </Grid>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    data-testid="submit-move-account-form-button"
                                    className={classes.submitButton}
                                    type="submit"
                                    disabled={submitting || hasValidationErrors || pristine || submitDisabled}
                                >
                                    Move Account
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </>
    );
}

MoveAccountForm.propTypes = {
    onSubmit: PropTypes.func,
    submitDisabled: PropTypes.bool,
    initialValues: PropTypes.object,
    organizations: PropTypes.array,
    brands: PropTypes.array,
    // accounts: PropTypes.array,
    selectedItem: PropTypes.object.isRequired,
};

export default MoveAccountForm;
