import React, { useState } from 'react';
import { Grid, InputAdornment, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Input from '../../../../components/Input';
import { VALIDATE_ON_MODIFIED } from '../../../../utils/constants';
import { BaseAndMaxBidOptions, RadioData, TextFieldData } from '../../CampaignManagementComponentData/RadioGroupData';
import CampaignManagementRadioGroup from '../../shared/CampaignManagementRadioGroup';
import { textFieldFormatHandler } from '../../CampaignManagementService';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 6,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    radio: {
        color: theme.palette.primary.main,
    },
    gridSection: {
        maxWidth: '21rem',
    },
    dollarSign: {
        '& .MuiTypography-colorTextSecondary': {
            color: theme.palette.techBlack,
        },
        marginRight: 0,
    },
}));

const BaseAndMaxBid = ({ textFieldValues, setTextFieldValues }) => {
    const classes = useStyles();

    const [radioSelections, setRadioSelections] = useState({
        [RadioData.baseAndMaxBidRadioKey]: '',
    });

    const textFieldKey = [TextFieldData.baseAndMaxBidTextFieldKey];

    return (
        <Grid item className={classes.gridSection}>
            <Field name="baseBid" component="input">
                {(props) => (
                    <Input
                        inputLabel="Base Bid"
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        disabled={false}
                        InputProps={{
                            'data-testid': `base-bid-input`,
                            inputMode: 'decimal',
                            maxLength: 255,
                            style: { fontWeight: 600, fontSize: '14px', height: '3rem' },
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    classes={{
                                        root: classes.dollarSign,
                                    }}
                                >
                                    $
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            textFieldFormatHandler(e.target.value, textFieldKey, textFieldValues, setTextFieldValues);
                        }}
                        value={textFieldValues[textFieldKey]}
                        validateOn={VALIDATE_ON_MODIFIED}
                        {...props}
                    />
                )}
            </Field>
            <InputLabel className={classes.inputLabel}>Max Bid</InputLabel>
            <CampaignManagementRadioGroup
                options={BaseAndMaxBidOptions}
                radioSelections={radioSelections}
                setRadioSelections={setRadioSelections}
                boldRadioLabel={false}
            />
        </Grid>
    );
};

BaseAndMaxBid.propTypes = {
    baseBid: PropTypes.number,
    textFieldValues: PropTypes.object,
    setTextFieldValues: PropTypes.func,
    option: PropTypes.object,
};

export default BaseAndMaxBid;
