/**
 *
 * OrgActionButton
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FlyoutAction } from 'shared/constants';
import AddButton from 'components/AddButton';
import { OrgPageTabs } from '../../containers/OrganizationPage/constants';

const OrgActionButton = ({ tab, onClick }) => {
    let title = '';
    let action = null;

    switch (tab) {
        case OrgPageTabs.ORGANIZATIONS.key:
            title = 'Create Organization';
            action = () => onClick(FlyoutAction.ADD_ORGANIZATION);
            break;
        case OrgPageTabs.BRANDS.key:
            title = 'Create Brand';
            action = () => onClick(FlyoutAction.ADD_BRAND);
            break;
        case OrgPageTabs.ACCOUNTS.key:
            title = 'Add Account';
            action = () => onClick(FlyoutAction.ADD_ACCOUNT);
            break;
        default:
            title = 'Create';
            action = () => {};
            break;
    }

    return (
        <div>
            <AddButton label={title} onClick={action} />
        </div>
    );
};

OrgActionButton.propTypes = {
    tab: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default OrgActionButton;
