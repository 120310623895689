import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AllCampaignsPage from 'containers/HomePage/Loadable';
import RecentViewedCampaignsPage from 'containers/RecentViewedPage/Loadable';
import CampaignReportingPage from 'containers/CampaignReportingPage/Loadable';
import StackedCampaignsPage from 'containers/StackedCampaignsPage/loadable';
import StackedReportingPage from 'containers/StackedReportingPage/Loadable';
import SnippetBuilderPage from 'containers/SnippetBuilderPage/Loadable';
import UrlBuilder from 'containers/UrlBuilder/Loadable';
import SearchResultsPage from 'containers/SearchResultsPage/Loadable';
import SummaryDashboardPage from 'containers/SummaryDashboardPage';
import IDAMUserListPage from 'containers/IDAMUserManagement/UserListPage/Loadable';
import PageNotFound from 'containers/PageNotFound';
import PageError from 'containers/PageError';
import IDAMLoginPage from 'containers/IDAMLoginPage';
import IDAMLoginSuccessPage from 'containers/IDAMLoginSuccessPage';
import DevPage from 'containers/DevPage';
import ResourcePage from 'containers/ResourcePage';
import TermsPage from 'containers/TermsAndConditions';
import SSEDemoPage from 'containers/DevPage/SSEDemoPage';
import IDAMInvite from 'containers/IDAMInvite';
import OrganizationPage from 'containers/OrganizationPage';
import CreateSponsoredCampaign from 'containers/CampaignCreation/CreateSponsoredCampaign';

// LAYOUTS
import DefaultLayout from 'layouts/default';
import IDAMLoginLayout from 'layouts/IDAMLogin';
import { IDAMResetPasswordPage } from 'containers/IDAMResetPassword';
import LayoutWithHeader from 'layouts/LayoutWithHeader';
import { Permissions, UserManagePermissions } from 'shared/constants/user/permissions';
import SummaryCampaignTypePage from '../containers/SummaryCampaignTypePage/SummaryCampaignTypePage';
import useFeatureFlags from '../utils/featureFlags/useFeatureFlags';
import PrivateRoute from './PrivateRoute';
import RouteWithLayout from './withLayout';
import useNotifier from '../notification/useNotifier';
import CampaignManagementSandboxPage from '../containers/CampaignManagement/SandboxPage';

const {
    CAN_USE_SNIPPET_BUILDER,
    CAN_USE_URL_CREATION_TOOL,
    CAN_VIEW_CAMPAIGN_REPORTS, // TODO: this is DEPRECATED, should be removed after new permissions are updated in Production,
    CAN_VIEW_OFFSITE_DISPLAY_CAMPAIGNS,
    CAN_VIEW_ONSITE_DISPLAY_CAMPAIGNS,
    CAN_VIEW_PAID_SEARCH_CAMPAIGNS,
    CAN_VIEW_PROXIMITY_CAMPAIGNS,
    CAN_VIEW_SOCIAL_CAMPAIGNS,
    CAN_VIEW_SPONSORED_RMP_CAMPAIGNS,
} = Permissions;

const viewAllCampaignPermissions = [
    CAN_VIEW_CAMPAIGN_REPORTS, // TODO: this is DEPRECATED, should be removed after new permissions are updated in Production,
    CAN_VIEW_OFFSITE_DISPLAY_CAMPAIGNS,
    CAN_VIEW_ONSITE_DISPLAY_CAMPAIGNS,
    CAN_VIEW_PAID_SEARCH_CAMPAIGNS,
    CAN_VIEW_PROXIMITY_CAMPAIGNS,
    CAN_VIEW_SOCIAL_CAMPAIGNS,
    CAN_VIEW_SPONSORED_RMP_CAMPAIGNS,
];

export const RoutedContent = () => {
    useNotifier();
    const flags = useFeatureFlags();
    return (
        <Switch>
            <Redirect from="/campaigns/dashboard" to="/dashboard" exact />
            <Redirect from="/campaigns/summary/:campaignType" to="/dashboard/:campaignType" exact />
            <RouteWithLayout path="/login" exact component={IDAMLoginPage} layout={IDAMLoginLayout} />
            <RouteWithLayout path="/idamlogin" exact component={IDAMLoginPage} layout={IDAMLoginLayout} />
            <Route path="/dev-sse" exact component={SSEDemoPage} />

            <RouteWithLayout
                path="/idamresetpassword"
                exact
                component={IDAMResetPasswordPage}
                layout={LayoutWithHeader}
            />

            <RouteWithLayout path="/registration/" exact component={IDAMInvite} layout={LayoutWithHeader} />
            <RouteWithLayout path="/terms" exact component={TermsPage} layout={LayoutWithHeader} />

            <Route path="/idamlogin/success" component={IDAMLoginSuccessPage} />
            <RouteWithLayout
                path="/idamlogin/fail"
                component={() => <IDAMLoginPage error />}
                layout={IDAMLoginLayout}
            />
            {flags.showDevPage && <Route path="/dev" exact component={DevPage} />}
            {flags.showDevPage && <PrivateRoute path="/auth-dev" exact component={DevPage} layout={DefaultLayout} />}
            <PrivateRoute
                path="/campaigns/sandbox"
                exact
                component={CampaignManagementSandboxPage}
                layout={DefaultLayout}
            />
            <PrivateRoute
                path="/campaigns"
                exact
                component={AllCampaignsPage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/dashboard"
                exact
                component={SummaryDashboardPage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/campaigns/recent"
                exact
                component={RecentViewedCampaignsPage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/campaigns/details/:slug"
                exact
                component={CampaignReportingPage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/campaigns/stacked/details/:slug"
                exact
                component={StackedReportingPage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/campaigns/stacked"
                exact
                component={StackedCampaignsPage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/campaigns/stacked/:tab"
                exact
                component={StackedCampaignsPage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/search/results"
                exact
                component={SearchResultsPage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/tools/snippetbuilder"
                exact
                component={SnippetBuilderPage}
                layout={DefaultLayout}
                permissions={[CAN_USE_SNIPPET_BUILDER]}
            />
            <PrivateRoute
                path="/tools/url-builder"
                exact
                component={UrlBuilder}
                layout={DefaultLayout}
                permissions={[CAN_USE_URL_CREATION_TOOL]}
            />
            <PrivateRoute
                path="/dashboard/:campaignType"
                exact
                component={SummaryCampaignTypePage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/management/user/all"
                exact
                component={IDAMUserListPage}
                layout={DefaultLayout}
                permissions={UserManagePermissions}
            />
            <PrivateRoute
                path="/management/organization"
                exact
                component={OrganizationPage}
                layout={DefaultLayout}
                permissions={[]}
            />
            <PrivateRoute
                path="/resources"
                exact
                component={ResourcePage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            <PrivateRoute
                path="/resources/:tab"
                exact
                component={ResourcePage}
                layout={DefaultLayout}
                permissions={viewAllCampaignPermissions}
            />
            {flags.showCampaignManagement && (
                <>
                    <PrivateRoute
                        path="/create/sponsored"
                        exact
                        component={CreateSponsoredCampaign}
                        layout={DefaultLayout}
                        layoutProps={{ removePadding: true }}
                        permissions={viewAllCampaignPermissions} // TODO: Update this to correct permissions
                    />
                    <PrivateRoute
                        path="/create/sponsored/:tab"
                        exact
                        component={CreateSponsoredCampaign}
                        layout={DefaultLayout}
                        layoutProps={{ removePadding: true }}
                        permissions={viewAllCampaignPermissions} // TODO: Update this to correct permissions
                    />
                </>
            )}
            <PrivateRoute path="/500" component={PageError} />
            <PrivateRoute path="" hasLayout={false} component={PageNotFound} />
        </Switch>
    );
};

RoutedContent.propTypes = {};
