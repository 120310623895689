/**
 *
 * CampaignSettingsForm
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { CampaignManagementRadioGroup } from '../shared/CampaignManagementRadioGroup';
import { CampaignSettingsRadioData, RadioData, TextFieldData } from '../CampaignManagementComponentData/RadioGroupData';
import { CampaignManagementNameField } from '../shared/CampaignManagementNameField';
import CampaignSettingsAttributions from './CampaignSettingsAttributions';
import CampaignManagementDatePickerRange from '../shared/CampaignManagementDatePickerRange';
import { formatForUSCurrency } from '../CampaignManagementService';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        margin: '5px',
    },
    formButton: {
        maxWidth: '42rem',
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '5rem',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    content: {
        backgroundColor: 'white',
        padding: '2rem',
    },
    pageContainer: {
        width: 'auto',
    },
    leftGrid: {
        height: '100%',
    },
    gridSection: {
        maxWidth: '42rem',
    },
    balanceLabel: {
        '&.MuiFormLabel-root': {
            height: 14,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
}));

// should be a prop when functional
const campaign = {
    campaignType: 'Sponsored Products',
    campaignName: 'FY25 Holiday Test Campaign',
    campaignBalance: 20000,
    startDate: '2022-04-17',
    endDate: '2023-05-18',
};

export function CampaignSettingsForm({ loading }) {
    const classes = useStyles();

    const [radioSelections, setRadioSelections] = useState({
        [RadioData.csDailyBudgetRadioKey]: '',
        [RadioData.csMonthlyBudgetRadioKey]: '',
        [RadioData.csDailyBudgetRadioKey]: '',
    });

    const [textFieldValues, setTextFieldValues] = useState({
        [TextFieldData.csBudgetTextFieldKey]: '',
        [TextFieldData.csMonthlyBudgetTextFieldKey]: '',
        [TextFieldData.csDailyBudgetTextFieldKey]: '',
    });

    const formattedBalance = formatForUSCurrency(campaign.campaignBalance);

    return (
        <Grid container className={classes.pageContainer}>
            <Grid className={classes.leftGrid}>
                <Form
                    onSubmit={() => {}}
                    initialValues={campaign}
                    validate={(values) => {
                        const errors = {};
                        if (values.confirmPassword !== values.password) {
                            errors.confirmPassword = 'Confirm password must match.';
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} className={classes.content}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h2" component="h2" sx={{ fontWeight: 300 }}>
                                        {campaign.campaignType} Campaign
                                    </Typography>
                                    <br />
                                    <Grid item xs={12}>
                                        <InputLabel className={classes.balanceLabel}>
                                            Account Available Balance
                                        </InputLabel>
                                        <Typography variant="h5" component="h5" sx={{ fontWeight: 300 }}>
                                            {formattedBalance}
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item className={classes.gridSection}>
                                        <CampaignManagementNameField
                                            campaignName={campaign.campaignName}
                                            label="Campaign Name"
                                            loading={loading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignManagementDatePickerRange dates={campaign} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignManagementRadioGroup
                                        options={CampaignSettingsRadioData}
                                        radioSelections={radioSelections}
                                        setRadioSelections={setRadioSelections}
                                        textFieldValues={textFieldValues}
                                        setTextFieldValues={setTextFieldValues}
                                        boldRadioLabel
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CampaignSettingsAttributions />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        data-testid="submit-campaign-setting-form-button"
                                        className={classes.formButton}
                                        disabled={false}
                                        type="submit"
                                    >
                                        <span>{submitting || loading ? 'Loading...' : 'Continue'}</span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            </Grid>
        </Grid>
    );
}

CampaignSettingsForm.propTypes = {
    loading: PropTypes.bool,
};

export default CampaignSettingsForm;
