/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint import/no-cycle: [2, { ignoreExternal: true }] */

import React, { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table as MuiTable,
    TableContainer as MuiTableContainer,
    TableHead as MuiTableHead,
    TableRow as MuiTableRow,
    TableCell as MuiTableCell,
    TableSortLabel as MuiTableSortLabel,
    TableBody as MuiTableBody,
    TextField,
    IconButton,
    LinearProgress,
} from '@material-ui/core';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import { isEmpty, size } from 'lodash';

import TablePagination from './TablePagination';
import TableCellText from './TableCellText';
import { tableSort } from './utils';
import { ColumnPropType } from './constants';
import { TableRowWithActions } from './TableRowWithActions';
import ParentRowCollapsible from './ParentRowCollapsible';

const tableStyles = makeStyles(({ palette, typography }) => ({
    root: {
        backgroundColor: 'white',
        border: `1px solid ${palette.borderGrey}`,
        width: 'initial',
    },
    loading: {
        padding: '30px 20px !important',
    },
    table: {
        overflowX: 'auto',
    },
    row: {
        '&:nth-child(odd) td': {
            backgroundColor: 'rgba(0, 0, 0, 0.025)',
        },
        '&:last-child td': {
            borderBottom: 'none',
        },
        '&.Mui-selected': {
            backgroundColor: `${palette.humanBlue}14`,
            '&:hover': {
                backgroundColor: `${palette.humanBlue}20`,
            },
        },
    },
    parentRowCell: {
        borderBottom: 'none',
    },
    rowLight: {
        backgroundColor: 'rgba(255, 255, 255)',
    },
    rowDark: {
        backgroundColor: 'rgba(0, 0, 0, 0.025)',
    },
    rowTotal: {
        '& td': {
            backgroundColor: `${palette.hoverBlue} !important`,
            fontWeight: 'bolder',
        },
    },
    cell: {
        padding: '0 16px 0 16px',
        color: `${palette.darkGray} !important`,
        margin: '0 0px 0 0',
        height: '65px',
        textAlign: 'left',
        '&[data-clickable="true"]': {
            cursor: 'pointer',
        },
        '&[data-line-break="false"]': {
            whiteSpace: 'nowrap',
            '@media (max-width: 768px)': {
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '165px',
            },
        },
    },
    textIndent: {
        paddingLeft: '65px',
    },
    textRight: {
        textAlign: 'right',
    },
    cellBorder: {
        border: `1px solid ${palette.borderGrey}`,
        borderTop: '0',
        borderLeft: '0',
    },
    cellHeadInner: {
        display: 'flex',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.54) !important',
        padding: '16px 0 16px 12px',
        minWidth: '80px',
        justifyContent: 'center',

        '& div': {
            lineHeight: 'normal',
        },
    },
    cellGroup: {
        '&[data-sort="true"]': {
            backgroundColor: 'rgba(0, 0, 0, 0.025)',
        },
    },
    cellFilterInner: {
        position: 'relative',
    },
    cellFilterClear: {
        position: 'absolute',
        right: '6px',
        top: '6px',
    },
    cellFilterInput: {
        width: '100%',
        '& input': {
            paddingRight: '36px',
        },
    },
    nestedCells: {
        borderBottom: 'none',
    },
    nestRows: {
        backgroundColor: 'rgba(0, 0, 0, 0.025)',
    },
    actionMenu: {
        border: 'none !important',
        textAlign: 'left !important',

        '&:hover': {
            backgroundColor: `${palette.humanBlue} !important`,
            color: palette.standardWhite,
        },
    },
    noneActionMenu: {
        fontFamily: typography.fontFamily,
        fontSize: '14px',
        padding: '7px 20px',
        color: palette.shadowGray,
    },
}));

const initFilterValues = (columns) => {
    const values = {};
    columns.forEach((column) => {
        if (column.filterable) {
            values[column.field] = '';
        }
    });

    return values;
};

const containIgnoreCase = (item, filterFieldValues) => {
    const filterFields = Object.keys(filterFieldValues);

    // eslint-disable-next-line no-restricted-syntax
    for (const field in item) {
        if (filterFields.includes(field)) {
            const filterValue = filterFieldValues[field];
            const value = item[field];
            if (filterValue && !value.toUpperCase().includes(filterValue.toUpperCase())) {
                return false;
            }
        }
    }

    return true;
};

const rightAlignTypes = ['number', 'integer', 'decimal', 'currency', 'percent', 'percent_decimal'];

export const Table = ({
    data,
    dataTotal,
    columns,
    totalColumns,
    noDataText,
    loading,
    summaryDashboardL1,
    actions,
    childActions,
    sort,
    onRowClick,
    onSortOrderChange,
    pageNumber,
    onUpdatePageNumber,
    keyFn,
    styledFields = [],
    externalSortControl = false,
}) => {
    const classes = tableStyles();
    const [internalSortOrder, setInternalSortOrder] = useState(sort?.order);
    const [internalSortColumn, setInternalSortColumn] = useState(sort?.column ? sort.column : sort);
    const [filterField, setFilterField] = useState(null);
    const [filterValues, setFilterValues] = useState(initFilterValues(columns));
    const [internalPageNumber, setInternalPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortLabelActive, setSortLabelActive] = useState(false);

    const determinedPageNumber = pageNumber !== undefined ? pageNumber : internalPageNumber;
    const sortColumn = externalSortControl ? sort?.column : internalSortColumn;
    const sortOrder = externalSortControl ? sort?.order : internalSortOrder;

    const columnsForTotalRow = totalColumns || columns;

    // Assigning __key will help with selecting rows
    let keyedItems = useMemo(
        () => data?.map((item) => ({ ...item, __key: keyFn ? keyFn(item) : JSON.stringify(item) })),
        [data, keyFn],
    );

    const parentNodesExist = useMemo(() => data?.some((item) => item.sharedOrg), [data]);

    if (parentNodesExist) {
        keyedItems = keyedItems.map((item) => {
            let formattedParentNode = { ...item };
            if (item.sharedOrg) {
                const response = item.sharedOrg.map((itemShared) => ({
                    ...itemShared,
                    __key: keyFn ? keyFn(itemShared) : JSON.stringify(itemShared),
                }));
                formattedParentNode = { ...formattedParentNode, sharedOrg: response };
            }
            return formattedParentNode;
        });
    }

    const filteredItems = useMemo(
        () =>
            Object.values(filterValues).length > 0 // Has filter values
                ? keyedItems.filter((item) => containIgnoreCase(item, filterValues))
                : keyedItems,
        [filterValues, keyedItems],
    );

    const sortedItems = useMemo(() => (sortColumn ? tableSort(filteredItems, sortOrder, sortColumn) : filteredItems), [
        filteredItems,
        sortColumn,
        sortOrder,
    ]);

    const itemsPerPage = sortedItems?.slice(
        determinedPageNumber * pageSize,
        determinedPageNumber * pageSize + pageSize,
    );

    const onSortChange = (column) => () => {
        setSortLabelActive(true);
        const isAsc = () => {
            if (sortColumn?.field === column.field && sortOrder === 'asc') {
                return true;
            }
            // check if first sort click is on last login column, if so sort descending first
            return sortColumn?.field !== 'last_login' && column.field === 'last_login';
        };

        const order = isAsc() ? 'desc' : 'asc';

        if (onSortOrderChange) {
            onSortOrderChange(column, order);
        }
        setInternalSortOrder(order);
        setInternalSortColumn(column);
    };

    const onFilterChange = (field) => () => {
        setFilterField(!filterField ? field : null);
    };

    const onFilterInputChange = (field) => (event) => {
        const { value } = event.target;

        setFilterValues((prevFilterValue) => ({
            ...prevFilterValue,
            [field]: value,
        }));
    };

    const onFilterInputClear = (field) => () => {
        setFilterValues((prevFilterValue) => ({
            ...prevFilterValue,
            [field]: '',
        }));
    };

    const onPageChange = (newPageNumber) => {
        if (onUpdatePageNumber !== undefined) {
            onUpdatePageNumber(newPageNumber);
        }
        setInternalPageNumber(newPageNumber);
    };

    const onPageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        if (onUpdatePageNumber !== undefined) onUpdatePageNumber(0);
        setInternalPageNumber(0);
    };

    const onTableRowClick = (item) => () => {
        if (onRowClick instanceof Function) {
            onRowClick(item);
        }
    };

    return (
        <>
            <MuiTableContainer className={classes.root}>
                <MuiTable className={classes.table} size="medium">
                    <colgroup>
                        {columns.map((column) => (
                            <col
                                key={column.field + column.label}
                                className={classes.cellGroup}
                                data-sort={sortLabelActive && sortColumn?.field === column.field}
                                width={column.width}
                            />
                        ))}
                        {actions && <col width="50px" />}
                    </colgroup>
                    <MuiTableHead>
                        <MuiTableRow>
                            {columns.map((column, columnIndex) => (
                                <MuiTableCell
                                    key={column.field + column.label}
                                    align={column.align}
                                    className={classNames(classes.cell, classes.cellBorder)}
                                >
                                    {column.sortable ? (
                                        <MuiTableSortLabel
                                            style={{ color: 'rgba(0, 0, 0, 0.54) !important' }}
                                            className={classes.cellHeadInner}
                                            active={sortLabelActive && sortColumn?.field === column.field}
                                            direction={sortColumn?.field === column.field ? sortOrder : 'asc'}
                                            data-testid={`table-sort-${columnIndex + 1}`}
                                            onClick={onSortChange(column)}
                                        >
                                            <div>{column.label}</div>
                                        </MuiTableSortLabel>
                                    ) : (
                                        <span className={classes.cellHeadInner}>{column.label}</span>
                                    )}
                                    {column.filterable && (
                                        <IconButton onClick={onFilterChange(column.field)}>
                                            <LocationSearchingIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </MuiTableCell>
                            ))}
                            {actions && (
                                <MuiTableCell className={classNames(classes.cell)}>
                                    <span className={classes.cellHeadInner}>Actions</span>
                                </MuiTableCell>
                            )}
                        </MuiTableRow>
                    </MuiTableHead>
                    {loading ? (
                        <caption className={classes.loading}>
                            <LinearProgress />
                        </caption>
                    ) : isEmpty(data) ? (
                        <caption>{noDataText || 'No Data Available'}</caption>
                    ) : (
                        <MuiTableBody>
                            {filterField && (
                                <MuiTableRow>
                                    {columns.map((column) => (
                                        <MuiTableCell
                                            key={column.field + column.label}
                                            className={classNames(classes.cell)}
                                        >
                                            {column.filterable && (
                                                <span className={classes.cellFilterInner}>
                                                    <TextField
                                                        className={classes.cellFilterInput}
                                                        variant="outlined"
                                                        size="small"
                                                        autoFocus={filterField === column.field}
                                                        value={filterValues[column.field]}
                                                        onChange={onFilterInputChange(column.field)}
                                                    />
                                                    <IconButton
                                                        className={classes.cellFilterClear}
                                                        size="small"
                                                        onClick={onFilterInputClear(column.field)}
                                                    >
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </span>
                                            )}
                                        </MuiTableCell>
                                    ))}
                                    {actions && <MuiTableCell className={classNames(classes.cell)}></MuiTableCell>}
                                </MuiTableRow>
                            )}
                            {itemsPerPage.map((item, itemIndex) =>
                                parentNodesExist && !isEmpty(item?.sharedOrg) ? (
                                    <React.Fragment key={`parentRow-${item.__key}`}>
                                        <ParentRowCollapsible
                                            item={item}
                                            itemIndex={itemIndex}
                                            itemsCount={itemsPerPage.length}
                                            classes={classes}
                                            columns={columns}
                                            rightAlignTypes={rightAlignTypes}
                                            actions={actions}
                                            childActions={childActions}
                                            onTableRowClick={onTableRowClick}
                                            onRowClick={onRowClick}
                                            filterValues={filterValues}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment key={`tableRow-${item.__key}`}>
                                        <TableRowWithActions
                                            item={item}
                                            itemIndex={itemIndex}
                                            classes={classes}
                                            parentNodesExist={parentNodesExist}
                                            columns={columns}
                                            rightAlignTypes={rightAlignTypes}
                                            actions={actions}
                                            onTableRowClick={onTableRowClick}
                                            onRowClick={onRowClick}
                                            filterValues={filterValues}
                                            styledFields={styledFields}
                                        />
                                    </React.Fragment>
                                ),
                            )}
                            {dataTotal && (
                                <MuiTableRow className={classNames(classes.row, classes.rowTotal)} tabIndex={-1} hover>
                                    <MuiTableCell className={classNames(classes.cell)}>Totals:</MuiTableCell>
                                    {columnsForTotalRow.map(
                                        (column, index) =>
                                            index > 0 && (
                                                <MuiTableCell
                                                    align={column.align}
                                                    key={column.field + column.label}
                                                    data-testid={`testid-${column.field}`}
                                                    className={
                                                        rightAlignTypes.includes(column.type)
                                                            ? classNames(classes.cell, classes.textRight)
                                                            : classNames(classes.cell)
                                                    }
                                                    data-line-break={column.lineBreak}
                                                >
                                                    <TableCellText
                                                        item={dataTotal}
                                                        column={column}
                                                        filterValue={filterValues[column.field]}
                                                        // hides total values for description fields
                                                        hidden={column.type === 'description'}
                                                    />
                                                </MuiTableCell>
                                            ),
                                    )}
                                    {actions && <MuiTableCell className={classNames(classes.cell)} />}
                                </MuiTableRow>
                            )}
                        </MuiTableBody>
                    )}
                </MuiTable>
            </MuiTableContainer>
            {!loading && !summaryDashboardL1 && (
                <TablePagination
                    pageNumber={determinedPageNumber}
                    pageSize={pageSize}
                    itemTotal={size(data)}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                />
            )}
        </>
    );
};

Table.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    dataTotal: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.shape(ColumnPropType).isRequired).isRequired,
    totalColumns: PropTypes.array,
    noDataText: PropTypes.string,
    loading: PropTypes.bool,
    summaryDashboardL1: PropTypes.bool,
    select: PropTypes.shape({
        type: PropTypes.oneOf(['single', 'multiple']).isRequired,
        onSelect: PropTypes.func.isRequired,
    }),
    sort: PropTypes.shape({
        column: PropTypes.object.isRequired,
        order: PropTypes.oneOf(['desc', 'asc', '']).isRequired,
    }),
    actions: PropTypes.func,
    childActions: PropTypes.func,
    onRowClick: PropTypes.func,
    onSortOrderChange: PropTypes.func,
    pageNumber: PropTypes.number,
    onUpdatePageNumber: PropTypes.func,
    keyFn: PropTypes.func,
    styledFields: PropTypes.array,
    externalSortControl: PropTypes.bool,
};

export default memo(Table);
