import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    subTitle: {
        height: 14,
        paddingBottom: '5px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
        whiteSpace: 'nowrap',
        marginTop: '.25rem',
    },
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 6,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    optionsBelowRadial: {
        paddingTop: 0,
    },
    sectionContainer: {
        maxWidth: '42rem',
    },
    dateFieldContainer: {
        paddingLeft: 0,
    },
    endDateField: {
        paddingLeft: '16px',
        '&.MuiGrid-root': {
            maxWidth: '21rem',
            paddingRight: 0,
        },
    },
    startDateField: {
        '&.MuiGrid-root': {
            maxWidth: '35rem',
            paddingRight: '14rem',
        },
    },
}));

export function CampaignReviewSchedule({ startDate, endDate, header }) {
    const classes = useStyles();

    const dateFormat = (date) => {
        if (date) {
            const datObj = new Date(date);
            const day = datObj.getUTCDate();
            const month = datObj.toLocaleString('default', { month: 'long' });
            const year = datObj.getUTCFullYear();
            return `${month} ${day}, ${year}`;
        }
        return '';
    };

    return (
        <div className={classes.sectionContainer}>
            <Typography
                variant="h5"
                component="h5"
                sx={{
                    fontWeight: 300,
                }}
            >
                {header}
            </Typography>
            <Grid container className={classes.dateFieldContainer}>
                <Grid item xs={4} className={classes.startDateField}>
                    <Typography variant="body1" className={classes.subTitle}>
                        Start Date
                    </Typography>
                    <Grid item className={classes.optionsBelowRadial}>
                        <InputLabel className={classes.inputLabel}>{dateFormat(startDate)}</InputLabel>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.endDateField}>
                    <Typography variant="body1" className={classes.subTitle}>
                        End Date (optional)
                    </Typography>
                    <Grid item className={classes.optionsBelowRadial}>
                        <InputLabel className={classes.inputLabel}>{dateFormat(endDate)}</InputLabel>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

CampaignReviewSchedule.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    header: PropTypes.string,
};

export default CampaignReviewSchedule;
