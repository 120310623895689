import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NumberTextField } from './CampaignManagementNumberTextField';

const useStyles = makeStyles((theme) => ({
    subTitle: {
        height: 14,
        paddingBottom: '5px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    optionTop: {
        '&.MuiTypography-root': {
            fontWeight: 500,
        },
    },
    optionsBelowRadial: {
        marginLeft: '33px',
        paddingTop: 0,
    },
    optionLabels: {
        '&.MuiTypography-root': {
            fontWeight: 600,
            paddingBottom: 0,
        },
    },
    box: {
        display: 'flex',
        height: '72px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: '42rem',
        flex: '1 0 40%',
    },
    radio: {
        marginLeft: '-11px',
    },
    radioWithSubTitle: {
        padding: '9px 9px 4px 9px',
    },
}));

export function CampaignManagementRadioOption({
    radioOption,
    radioSelections,
    boldRadioLabel,
    textFieldValues,
    setTextFieldValues,
}) {
    const classes = useStyles();

    const radioOptionMap = (option) => {
        if (option.label && option.subLabel && option.textField) {
            return (
                <Grid id="radio-label-subLabel-textField">
                    <Grid item className={classes.optionTop}>
                        <FormControlLabel
                            value={option.value}
                            control={
                                <Radio
                                    className={classes.radioWithSubTitle}
                                    id="radio-label-subLabel-textField"
                                    name={option.name}
                                    checked={radioSelections[option.key] === option.value}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography
                                    variant="body1"
                                    className={classes.optionLabels}
                                    sx={{
                                        marginBottom: 0,
                                        paddingBottom: 0,
                                    }}
                                >
                                    {option.label}
                                </Typography>
                            }
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item className={classes.optionsBelowRadial}>
                        <Typography variant="body1" className={classes.subTitle}>
                            {option.subLabel}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.optionsBelowRadial}>
                        <NumberTextField
                            option={option}
                            radioSelections={radioSelections}
                            textFieldValues={textFieldValues}
                            setTextFieldValues={setTextFieldValues}
                        />
                    </Grid>
                </Grid>
            );
        }
        if (option.label && option.subLabel && !option.textField) {
            return (
                <Grid id="radio-label-subLabel">
                    <Grid item>
                        <FormControlLabel
                            value={option.value}
                            control={
                                <Radio
                                    color="primary"
                                    name={option.name}
                                    checked={radioSelections[option.key] === option.value}
                                    className={classes.radioWithSubTitle}
                                />
                            }
                            label={
                                <Typography
                                    variant="body1"
                                    className={classes.optionLabels}
                                    sx={{
                                        marginBottom: 0,
                                    }}
                                >
                                    {option.label}
                                </Typography>
                            }
                            labelPlacement="end"
                            id="label-radio-button"
                        />
                    </Grid>
                    <Grid item className={classes.optionsBelowRadial}>
                        <Typography variant="body1" className={classes.subTitle}>
                            {option.subLabel}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (!option.label && !option.subLabel && option.textField) {
            return (
                <Grid id="radio-label-subLabel-textField" className={classes.box}>
                    <Radio
                        color="primary"
                        className={classes.radio}
                        name={option.name}
                        value={option.value}
                        checked={radioSelections[option.key] === option.value}
                    />
                    <NumberTextField
                        option={option}
                        radioSelections={radioSelections}
                        textFieldValues={textFieldValues}
                        setTextFieldValues={setTextFieldValues}
                    />
                </Grid>
            );
        }
        return (
            <Grid item id="radio-label">
                <FormControlLabel
                    value={option.value}
                    control={
                        <Radio
                            color="primary"
                            name={option.name}
                            checked={radioSelections[option.key] === option.value}
                        />
                    }
                    label={
                        <Typography variant="body1" className={boldRadioLabel ? classes.optionLabels : ''}>
                            {option.label}
                        </Typography>
                    }
                    labelPlacement="end"
                    id="label-radio-button"
                />
            </Grid>
        );
    };

    return radioOptionMap(radioOption);
}

CampaignManagementRadioOption.propTypes = {
    radioOption: PropTypes.object,
    radioSelections: PropTypes.object,
    boldRadioLabel: PropTypes.bool,
    textFieldValues: PropTypes.object,
    setTextFieldValues: PropTypes.func,
};

export default CampaignManagementRadioOption;
